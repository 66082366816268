<template>
  <div
    v-if="show"
    class="modal-exit"
  >
    <div class="modal-container">
      <div class="modal-container-header">
        <CrossIcon
          class="modal-container-header__icon"
          @click="emit('close-dialog')"
        />
      </div>
      <div class="modal-container-body">
        {{ t('logout_modal.title') }}
      </div>
      <div class="modal-container-footer">
        <GlobusButtonPrimary
          class="button"
          @click="exit"
        >
          <SvgIcon
            name="logout-icon"
            width="15px"
            height="15px"
          />
          {{ t('logout_modal.button.accept') }}
        </GlobusButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script setup>
import CrossIcon from "@/assets/icons/CrossIcon.vue";
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import SvgIcon from "@/components/common/svgButton.vue";
import { exit } from "@/composables/functionExit";
import { t } from "@/controllerLK/GlobusLocalization"; 

const emit = defineEmits(["close-dialog"]);

defineProps({
  show: {
    type: Boolean,
    default: false
  }
});
</script>

<style lang="scss" scoped>
.modal-exit {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 50%);

  .modal-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 240px;
    padding: 20px;
    border-radius: 20px;
    background: #FFF;
    gap: 20px;

    &-header {
      display: flex;
      justify-content: flex-end;

      &__icon {
        color: #808080;
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

    &-body {
      font-size: 28px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1F9AFF;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .button {
        width: 200px;
      }
    }
  }
}
</style>