<script setup>

const emit = defineEmits(["onChange"]); 

const props = defineProps({
  checkBoxValue: {
    type: Boolean,
    required: true,
    default: false
  }
});

</script>

<template>
  <div class="checkbox-wrapper">
    <svg
      v-if="props.checkBoxValue"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="emit('onChange', false)"
    >
      <rect
        width="16"
        height="16"
        rx="2"
        fill="#1890FF"
      />
      <path
        d="M7.10497 12.6041L7.08735 12.6217L2.6875 8.22185L4.12018 6.78917L7.10502 9.77402L11.879 5L13.3117 6.43268L7.12264 12.6218L7.10497 12.6041Z"
        fill="white"
      />
    </svg>

    <svg
      v-else
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="emit('onChange', true)"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="1.5"
        fill="white"
        stroke="#D9D9D9"
      />
    </svg>
  </div>
</template>

<style scoped lang="scss">

.checkbox-wrapper {
  cursor: pointer;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>