<script setup>
import {defineProps, ref, defineEmits, onMounted, watch, provide} from "vue";
import { t } from "@/controllerLK/GlobusLocalization";
import {useUser} from "@/store/user";
import {useProjects} from "@/store/projects";
import {createProjectForm} from "@/composables/constans/formData";
import ProjectModalArchive from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalArchive.vue";
import ProjectModalHeader from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalHeader.vue";
import ProjectModalName from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalName.vue";
import ProjectModalModules from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalModules.vue";
import ProjectModalCountry from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry.vue";
import ProjectModalControl from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalControl.vue";
import BlockView from "@/components/common/BlockView.vue";
import GlobusDialog from "@/components/common/GlobusDialog.vue";


onMounted(async () => {
  await userStore.getUser().then(() => {
    createProjectFormData.value.users.push(userStore.user?.userId);
  });
  await userStore.getOrganizationMembers();
});

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isArchive: {
    type: Boolean,
    default: false
  },
  project: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(["closeModal", "updateProjectList"]);

const userStore = useUser();
const projectStore = useProjects();

const errorList = ref([]);
provide("errorList", errorList);

const createProjectFormData = ref(JSON.parse(JSON.stringify(createProjectForm)));
provide("createProjectFormData", createProjectFormData);

const selectedRegionList = ref([]);
provide("selectedRegionList", selectedRegionList);

watch(() => props.show, async (status) => {
  errorList.value = [];
  if (status === false) {
    createProjectFormData.value = JSON.parse(JSON.stringify(createProjectForm));
    selectedRegionList.value = []; 
  } else {
    if (props.isEdit) {
      createProjectFormData.value = JSON.parse(JSON.stringify(props.project));
      selectedRegionList.value = await projectStore.getProjectMapList(props.project.id);
    }
  }
});

const isShowUnsavedChangesDialog = ref(false);
const projectModalControlRef = ref(null);

const saveUnsavedChanges = () => {
  projectModalControlRef.value.createProject();
  isShowUnsavedChangesDialog.value = false;
};
</script>

<template>
  <BlockView
    v-if="props.show"
    @click="isEdit ? isShowUnsavedChangesDialog = true : emit('close-modal')"
  />

  <div
    v-if="props.show"
    class="modal-create-project"
  >
    <ProjectModalHeader
      :is-edit="props.isEdit"
      @close-modal="emit('closeModal')"
    />

    <ProjectModalName />

    <ProjectModalArchive
      v-if="props.isEdit"
      :is-archive="props.isArchive"
      @close-project-modal="emit('closeModal')"
    />

    <ProjectModalModules />

    <ProjectModalCountry
      :is-edit="props.isEdit"
    />

    <ProjectModalControl
      ref="projectModalControlRef"
      :is-edit="props.isEdit"
      @update-project-list="emit('updateProjectList')"
      @close-modal="emit('closeModal')"
    />
  </div>
  <GlobusDialog
    v-if="isShowUnsavedChangesDialog"
    :cancel-button-text="t('lk_project_modal.unsaved_changes.button.cancel')"
    :confirm-button-text="t('lk_project_modal.unsaved_changes.button.save')"
    class="unsaved-changed-dialog"
    :title="t('lk_project_modal.unsaved_changes.title')"
    @confirm="saveUnsavedChanges"
    @cancel="isShowUnsavedChangesDialog = false; emit('closeModal')"
  >
    <div class="unsaved-changed-dialog__text">
      {{ t('lk_project_modal.unsaved_changes.message') }}
    </div>
  </GlobusDialog>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.unsaved-changed-dialog {
  z-index: 2001;

  &__text {
    color: #373D3F;
  }
}

.modal-create-project {
  position: fixed;
  z-index: 2001;
  top: 0;
  right: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  width: 555px;
  height: 100%;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px 0 #00000024;
  gap: 30px;
}

</style>