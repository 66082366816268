import { createRouter, createWebHistory  } from "vue-router";
import LKPage from "../pages/LKPage.vue";
import Login from "../pages/LoginPage.vue";
import { mainStorage } from "@/composables/tools/main-storage.tools";
import { goMainPage } from "@/composables/ModuleCommunication";
import { useStore } from "@/store";
import {useProjects} from "@/store/projects";
import {projectDisabledModules} from "@/composables/constans/modules";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/lk",
    name: "lk",
    component: LKPage
  },
];

const history = createWebHistory();

const router = createRouter({
  history: history,
  routes
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();

  if (to.name === "login") {
    if (mainStorage.getItem("access_token")) {
      goMainPage();
    }
  }

  if (!mainStorage.getItem("access_token")) {
    if (to.name !== "login") {
      await router.push("login");
    }
  } else if (to.name === "login") {
    goMainPage();
  }
  else if(to.fullPath === "/" && store.mainHrefApp){
    goMainPage();
  }

  const projectStore = useProjects();


  if (projectStore.activeProject.modules
      && !projectStore.activeProject?.modules.includes(store.activeModule.dataName)
      && !projectDisabledModules.includes(store.activeModule.dataName)) {
    if (to.name !== "lk") {
      await router.push("/lk");
      alert(`В активном проекте отсутствует подключенный модуль ${store.activeModule.name}.
      Переключите проект или создайте новый!`);
    }
  } else {
    if (window.routerApp) {
      window.routerApp.replace(to);
    }
  }
  next();
});

window.routerLK = router; 
export default router;
