// Список модулей, для которых не учитывается активный проект.
export const projectDisabledModules = ["module_proxy"];

// Список всех модулей
export const modules = [
    {
        name: "Досье",
        dataName: "module_dossier",
        url: "https://dossier.globus.guru",
        meta: {
            useMenu: true
        }
    },
    {
        name: "Глобус",
        dataName: "module_globus",
        url: "https://globus.guru",
        meta: {
            useMenu: true
        }
    },
    {
        name: "Карта",
        dataName: "module_map",
        url: "https://maps.globus.guru",
        meta: {
            useMenu: true
        }
    },
    {
        name: "Публикатор",
        dataName: "module_publisher",
        url: "https://publisher.globus.guru",
        meta: {
            useMenu: true
        }
    },
    {
        name: "Визуализатор",
        dataName: "module_visualizer",
        url: "https://visualizer.globus.guru",
        meta: {
            useMenu: true
        }
    },
    {
        name: "Главный",
        dataName: "module_main",
        url: "https://visualizer.globus.guru",
        meta: {
            useMenu: true
        }
    },
    {
        name: "Прокси",
        dataName: "module_proxy",
        url: "https://proxy.globus.guru",
        meta: {
            useMenu: false
        }
    }
];

