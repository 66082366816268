import {getToken} from "@/composables/auth";
import {exit} from "@/composables/functionExit";
import {changeLoadApp, changeShow, setProject} from "@/composables/ModuleCommunication";
import {projectDisabledModules} from "@/composables/constans/modules";
import {useUser} from "@/store/user";
import {useProjects} from "@/store/projects";
import {useStore} from "@/store";
import {useGlobusLocal} from "@/controllerLK/GlobusLocalization";
import {useRouter} from "vue-router";
import { t } from "@/controllerLK/GlobusLocalization";

export const personalAreaInitialize = async () => {
    const router = useRouter();
    const store = useStore();
    const userStore = useUser();
    const projectStore = useProjects();
    const { initGlobusLocal } = useGlobusLocal();

    try {
        const token = await getToken();

        if (token) {
            await userStore.getUser();

            if (userStore.user.language.toLowerCase() !== localStorage.getItem("globusLanguage")) {
                localStorage.setItem("globusLanguage", userStore.user.language.toLowerCase());
                window.location.reload();
            }

            initGlobusLocal(userStore.user.language.toLowerCase(), "personal_area");

            if (!userStore.user.modules.find(item => item.moduleId === store.activeModule.dataName)
                && store.activeModule.dataName !== "module_proxy" ) {
                exit();
                store.createNotify("Нет доступа к модулю",
                    "У данного пользователя нет доступа к модулю, обратитесь к администратору", "");
                store.loader = false;
                return;
            }

            if (userStore.user.activeProject) {
                await projectStore.getProjects();
                let project = await projectStore.getProject(userStore.user.activeProject);

                if (project) {
                    projectStore.activeProject = project;
                    setProject(project);

                    changeLoadApp(true);
                    changeShow(true);


                    if (projectStore.activeProject.modules
                        && !projectStore.activeProject?.modules.includes(store.activeModule.dataName)
                        && !projectDisabledModules.includes(store.activeModule.dataName)) {
                        await router.push("/lk");
                        alert(t("toast.error.no_module_one") + ` ${store.activeModule.name}.
             ` + t("toast.error.no_module_two"));
                    }
                }
            } else {
                changeLoadApp(false);
                changeShow(false);
            }
        }
    } catch(e){
        console.log(e);
    }
    store.loader = false;
};