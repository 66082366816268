<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {inject, ref} from "vue";
import {useProjects} from "@/store/projects";
// eslint-disable-next-line max-len
import AccessListModal from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalControl/AccessListModal.vue";
import { t } from "@/controllerLK/GlobusLocalization";

const props = defineProps({
  isEdit: {
    type: Boolean,
    required: true,
    default: false
  }
});
const emit = defineEmits(["closeModal", "updateProjectList"]);

const projectStore = useProjects();

const showUserList = ref(false);

const errorList = inject("errorList");
const createProjectFormData = inject("createProjectFormData");
const selectedRegionList = inject("selectedRegionList");

const submitted = ref(false);



/* POST Запрос создания нового проекта **/
const createProject = async () => {
  submitted.value = true;
  errorList.value = [];
  createProjectFormData.value.name = createProjectFormData.value.name.trim();
  if (createProjectFormData.value.name.length === 0) {
    errorList.value.push("noName");
  }

  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)

  if (createProjectFormData.value.name.search(emojiRegEx) !== -1) {
    errorList.value.push("noEmoji");
  }

  const moduleList = createProjectFormData.value.modules;
  if (moduleList.length === 0) {
    errorList.value.push("noModule");
  }

  if (moduleList.length === 1 && moduleList[0] === "module_main") {
    errorList.value.push("noModule");
  }

  if (!errorList.value.length > 0) {
    if (!props.isEdit) {
      await projectStore.createProject(createProjectFormData.value).then((res) => {

        // Создание карт
        for (let region of selectedRegionList.value) {
          if (!region.file) {
            projectStore.createMap(res.id, region.id, region.name);
          } else {
            projectStore.uploadMap(res.id, region.file);
          }
        }

      });
    } else {
      await projectStore.updateProject(createProjectFormData.value);
    }
    emit("closeModal");
    emit("updateProjectList");
  }
  submitted.value = false; 
};
defineExpose({createProject});
</script>

<template>
  <div class="project-control">
    <div
      class="project-control__add-member"
      @click="showUserList = true"
    >
      <SvgIcon
        height="15px"
        name="access-control-blue"
        width="15px"
      />

      <span>{{ t('project_modal_control.title') }}</span>

      <SvgIcon
        height="15px"
        name="lock-blue"
        width="15px"
      />
    </div>
    <div
      class="project-control__create"
      @click="!submitted ? createProject() : false"
    >
      <span v-if="!isEdit">{{ t('project_modal_control.button.create') }}</span>
      <span v-else>{{ t('project_modal_control.button.save') }}</span>
    </div>
  </div>

  <AccessListModal
    :show-user-list="showUserList"
    @close-modal="showUserList = false"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.project-control {
  display: flex;
  justify-content: space-between;
  user-select: none;

  &__add-member {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 220px;
    height: 40px;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    color: $globe-active-color;
    border-radius: 8px;
    background: $background-color;
    gap: 10px;
  }

  &__add-member:hover {
    background: $hover-active-color;
  }

  &__create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 40px;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    color: #FFFFFF;
    border-radius: 8px;
    background: $blue-dark;
  }
}
</style>