export function moduleInit(settings, router, LK) {
  window.routerApp = router;
  window.addEventListener("SetProject", (event) => {
    LK.project = event.detail;
  });
  window.addEventListener("ChangeLoadModule", (event) => {
    LK.loadApp = event.detail;
  });
  window.addEventListener("ChangeShowModule", (event) => {
    LK.showContent = event.detail;
  });
  window.addEventListener("SetUser", (event) => {
    LK.user = event.detail;
  });

  window.addEventListener("ChangeLanguage", (event) => {
    LK.language = event.detail;
  });

  const event = new CustomEvent("SetModule", { detail: settings });
  window.dispatchEvent(event);
}

export function initRouterLK(LK, settings) {
  const routerLK = window.routerLK;
  if(routerLK.currentRoute.value.fullPath === "/") {
    settings ? routerLK.push(settings.path) : routerLK.push("/main");
  }
  routerLK.afterEach(async (to) => {
    if(LK) {
      LK.currentRouteLK = to; 
    }
  });
}