<template>
  <svg
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.879395"
      width="44"
      height="44"
      rx="22"
    />
    <g clip-path="url(#clip0_415_1360)">
      <path
        d="M15.1499 20.2162C13.8418 20.2162 12.7715 21.2865
        12.7715 22.5946C12.7715 23.9027 13.8418 24.973 15.1499
         24.973C16.458 24.973 17.5282 23.9027 17.5282 22.5946C17.5282
         21.2865 16.458 20.2162 15.1499 20.2162ZM29.4201
          20.2162C28.112 20.2162 27.0418 21.2865 27.0418 22.5946C27.0418
          23.9027 28.112 24.973 29.4201 24.973C30.7282
           24.973 31.7985 23.9027 31.7985 22.5946C31.7985 21.2865 30.7282
           20.2162 29.4201 20.2162ZM22.285 20.2162C20.9769
            20.2162 19.9066 21.2865 19.9066 22.5946C19.9066 23.9027 20.9769
            24.973 22.285 24.973C23.5931 24.973 24.6634
             23.9027 24.6634 22.5946C24.6634 21.2865 23.5931 20.2162 22.285 20.2162Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_415_1360">
        <rect
          x="8.01465"
          y="8.32433"
          width="28.5405"
          height="28.5405"
          rx="14.2703"
        />
      </clipPath>
    </defs>
  </svg>
</template>