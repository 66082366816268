<script setup>
import NotificationItem from "@/components/Notification/NotificationItem.vue";
import {useStore} from "@/store";
const store = useStore(); 
</script>

<template>
  <div
    v-if="store.notifications[0]"
    class="notification-block"
  >
    <NotificationItem
      v-for="item in store.notifications"
      :key="item"
      :item="item"
    />
  </div>
</template>

<style scoped lang="scss">
.notification-block {
  position: fixed;
  z-index: 5000;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>