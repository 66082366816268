<script setup>
import { defineModel } from "vue";

defineProps({
  label: {
    type: String,
    required: true,
  }
}); 

const model = defineModel();

</script>

<template>
  <div class="input-wrapper">
    <label for="input">{{ label }}</label>
    <input
      id="input"
      v-model="model"
      type="text"
    >
  </div>
</template>

<style scoped lang="scss">

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: #212327;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  input {
    all: unset;
    border: 1px solid #DEDEDE;
    background: #FFFFFF;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
    text-align: left;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #212327;
    box-shadow: inset 0 0 20px 20px #FFFFFF;
  }
}


</style>