<script setup>

</script>

<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.722 6.59537C13.1727 5.43819 12.519 4.49443 11.761
      3.76412L10.966 4.55912C11.6143 5.17865 12.179
       5.98912 12.6673 6.9985C11.3673 9.68912 9.52197 10.9672
       7.00009 10.9672C6.24311 10.9672 5.54587 10.8507
        4.90837 10.6176L4.04697 11.479C4.93238 11.8878 5.91676
        12.0922 7.00009 12.0922C10.0032 12.0922 12.2438
         10.5282 13.722 7.40006C13.7814 7.27426 13.8122 7.13685
         13.8122 6.99772C13.8122 6.85858 13.7814 6.72118 13.722
          6.59537ZM12.7287 1.58537L12.0626 0.918497C12.051 0.906875
          12.0372 0.897655 12.022 0.891364C12.0068 0.885074
           11.9906 0.881836 11.9742 0.881836C11.9577 0.881836 11.9415
           0.885074 11.9263 0.891364C11.9111 0.897655 11.8973
            0.906875 11.8857 0.918497L10.1768 2.62662C9.23462 2.14537
            8.17571 1.90475 7.00009 1.90475C3.99697 1.90475
             1.75634 3.46881 0.278215 6.59693C0.218802 6.72274 0.187988
             6.86015 0.187988 6.99928C0.187988 7.13841 0.218802
              7.27582 0.278215 7.40162C0.868736 8.64537 1.57967 9.6423
              2.41103 10.3924L0.75759 12.0454C0.734166 12.0688
               0.721008 12.1006 0.721008 12.1337C0.721008 12.1669 0.734166
               12.1987 0.75759 12.2221L1.42462 12.8891C1.44806
                12.9125 1.47984 12.9257 1.51298 12.9257C1.54612 12.9257
                1.5779 12.9125 1.60134 12.8891L12.7287 1.76225C12.7403
                 1.75064 12.7495 1.73685 12.7558 1.72168C12.7621 1.7065
                 12.7653 1.69024 12.7653 1.67381C12.7653 1.65738 12.7621
                  1.64112 12.7558 1.62594C12.7495 1.61077 12.7403
                  1.59698 12.7287 1.58537ZM1.3329 6.9985C2.63447 4.30787
                   4.47978 3.02975 7.00009 3.02975C7.85228 3.02975
                   8.62712 3.176 9.33009 3.47334L8.23165 4.57178C7.71145 4.29422
                    7.1158 4.19121 6.5326 4.27794C5.94939 4.36468
                    5.4095 4.63656 4.99258 5.05348C4.57565 5.47041 4.30377 6.0103
                     4.21704 6.59351C4.1303 7.17671 4.23331
                     7.77235 4.51087 8.29256L3.20743 9.596C2.48603 8.95928 1.86415 8.09662
                      1.3329 6.9985ZM5.18759 6.9985C5.18786
                      6.72297 5.25313 6.4514 5.37808 6.20584C5.50303 5.96027 5.68414 5.74765
                       5.9067 5.58523C6.12926 5.42281 6.387
                       5.31519 6.65898 5.27109C6.93095 5.227 7.20949 5.24769 7.47197 5.33147L5.27056
                        7.53287C5.21538 7.36012 5.18739 7.17985 5.18759 6.9985Z"
      fill="#626262"
    />
    <path
      d="M6.93786 8.75109C6.8838 8.75109 6.83052 8.74859
      6.7777 8.74375L5.95239 9.56906C6.44878 9.75916 6.98961 9.80141 7.5095
       9.69069C8.02939 9.57997 8.50608 9.32103 8.88194
       8.94517C9.2578 8.56931 9.51674 8.09262 9.62746 7.57273C9.73818 7.05285
        9.69593 6.51202 9.50583 6.01562L8.68052 6.84094C8.68536
        6.89375 8.68786 6.94703 8.68786 7.00109C8.68798 7.23094 8.6428
         7.45856 8.5549 7.67094C8.467 7.88331 8.3381 8.07628
         8.17557 8.23881C8.01305 8.40133 7.82008 8.53023 7.6077
         8.61813C7.39533 8.70604 7.16771 8.75122 6.93786 8.75109Z"
      fill="#626262"
    />
  </svg>
</template>

<style scoped lang="scss">

</style>