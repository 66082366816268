<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {inject, ref} from "vue";
// eslint-disable-next-line max-len
import CountryComboBox from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry/CountryComboBox.vue";
import {useProjects} from "@/store/projects";
import BlockView from "@/components/common/BlockView.vue";
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import { t } from "@/controllerLK/GlobusLocalization";
import MapUploadDialog
  from "@/pages/LKPage/LKWindow/LKProjectWindow/ProjectModal/ProjectModalCountry/MapUploadDialog.vue";

const projectStore = useProjects();

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
});
const createProjectFormData = inject("createProjectFormData");

const isShowRegionOption = ref(false);

const uploadMap = () => {
  isShowRegionOption.value = false;
  isShowMapUploadDialog.value = true;
};
const isShowMapUploadDialog = ref(false);

const regionSearch = () => {
  isShowRegionSearch.value = true;
  isShowRegionOption.value = false;
};

const isShowRegionSearch = ref(false);

const addRegionOption = ref([
  {
    name: t("project_modal_country.options.search"),
    icon: null,
    action: regionSearch
  },
  {
    name: t("project_modal_country.options.upload"),
    icon: "upload",
    action: uploadMap
  }
]);

const selectedRegionList = inject("selectedRegionList");

/* Запрос удаления карт при редактирование / Удаление из списка при создание проекта */
const deleteRegionFromList = (item) => {
  if (props.isEdit) {
    projectStore.deleteProjectMap(createProjectFormData.value.id, item); 
    selectedRegionList.value.splice(selectedRegionList.value.indexOf(item), 1);
  } else {
    selectedRegionList.value.splice(selectedRegionList.value.indexOf(item), 1);
  }
};

</script>

<template>
  <div class="country-wrapper">
    <div class="header">
      <div class="header__title">
        {{ t('project_modal_country.title') }}
      </div>

      <div v-if="!isShowRegionSearch">
        <GlobusButtonPrimary
          :is-clicked="isShowRegionOption"
          @click="isShowRegionOption = true"
        >
          {{ t('project_modal_country.button.add') }}
          <SvgIcon
            name="plus_bold"
            width="14px"
            height="14px"
            color="#FFF"
          />
        </GlobusButtonPrimary>

        <div
          v-if="isShowRegionOption"
          class="dropdown"
        >
          <div
            v-for="item in addRegionOption"
            :key="item"
            class="dropdown__item"
            @click="item.action"
          >
            <SvgIcon
              v-if="item.icon"
              :name="item.icon"
              width="16px"
              height="16px"
            />
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-else>
        <CountryComboBox
          :is-edit="props.isEdit"
          @close-region-search="isShowRegionSearch = false"
        />
      </div>
    </div>
    <div class="body scroll">
      <div
        v-for="item in selectedRegionList"
        :key="item"
        class="selected-region"
      >
        {{ item.name }}
        <SvgIcon
          class="trash"
          name="trash"
          width="16px"
          height="16px"
          @click="deleteRegionFromList(item)"
        />
      </div>
    </div>
  </div>


  <MapUploadDialog
    :project-id="createProjectFormData.id"
    :is-edit="props.isEdit"
    :is-show-map-upload-dialog="isShowMapUploadDialog"
    @close-map-upload-dialog="isShowMapUploadDialog = false"
  />

  <BlockView
    v-if="isShowRegionOption"
    @click="isShowRegionOption = false"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.country-wrapper {
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 15px;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  gap: 6px;
}

.body {
  overflow-y: scroll;

  .selected-region {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px 14px 10px 14px;
    color: $globus-button-secondary-text-color;

    .trash {
      cursor: pointer;
    }

    &:hover {
      border-radius: 8px;
      background: $globus-background-primary-color;
    }
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 40px;

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: $text-01;
  }

  .dropdown {
    position: absolute;
    z-index: 2000;
    top: 50px;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 6px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px 0 #131E2726;
    gap: 4px;

    &__item {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 6px 14px 6px 14px;
      cursor: pointer;
      color: $text-01;
      border-radius: 8px;
      gap: 4px;

      &:hover {
        background: $hover-active-color;
      }
    }
  }
}
</style>
