<script setup>
import UserAvatar from "@/pages/LKPage/LKWindow/LKProfileWindow/LKUserAvatar.vue";
import {useUser} from "@/store/user";
import {onMounted, ref} from "vue";
import TextInput from "@/pages/LKPage/LKWindow/LKProfileWindow/Input/TextInput.vue";
import { t } from "@/controllerLK/GlobusLocalization";

defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:isEdit"]);

const userStore = useUser();

onMounted(async () => {
  await userStore.getUser().then(() => {
    userFormData.value = JSON.parse(JSON.stringify(userStore.user));
  });
});

const userFormData = ref({
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  image: "",
  email: ""
});

const errorList = ref({
  firstName: false,
  middleName: false,
  lastName: false,
  phone: false,
  email: false
});


const saveUser = async () => {
  errorList.value = {
    firstName: false,
    middleName: false,
    lastName: false,
    phone: false,
    email: false
  };

  if (userFormData.value.lastName !== null && userFormData.value.lastName.length <= 0) {
    errorList.value.lastName = t("lk_edit_profile.error.required");
  } else {
    errorList.value.lastName = validateName(userFormData.value.lastName);
  }

  if (userFormData.value.middleName !== null && userFormData.value.middleName.length <= 0) {
    errorList.value.middleName = t("lk_edit_profile.error.required");
  } else {
    errorList.value.middleName = validateName(userFormData.value.middleName);
  }

  if (userFormData.value.firstName !== null && userFormData.value.firstName.length <= 0) {
    errorList.value.firstName = t("lk_edit_profile.error.required");
  } else {
    errorList.value.firstName = validateName(userFormData.value.firstName);
  }


  if (userFormData.value.email !== null && userFormData.value.email.length === 0) {
    errorList.value.email = t("lk_edit_profile.error.required");
  } else if (!validateEmail(userFormData.value.email)) {
    errorList.value.email = t("lk_edit_profile.error.email_format");
  }



  if (userFormData.value.phone !== null && userFormData.value.phone.length <= 0) {
    errorList.value.phone = t("lk_edit_profile.error.required");
  }
  else if (!validatePhone(userFormData.value.phone)) {
    errorList.value.phone = t("lk_edit_profile.error.phone_format");
  }

  for (const error in errorList.value) {
    if (errorList.value[error]) return false;
  }

  userFormData.value.lastName = userFormData.value.lastName.trim();
  userFormData.value.middleName = userFormData.value.middleName.trim();
  userFormData.value.firstName = userFormData.value.firstName.trim();

  await userStore.updateUser(userFormData.value).then(() => {
    emit("update:isEdit", false);
  });
};

const validatePhone = (phone) => {
  const re = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm;
  return re.test(phone);
};

const validateEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  const startMail = email.split("@");
  const endMail = startMail[1].split(".");

  if (startMail[0].startsWith("-")) return false;
  if (startMail[0].endsWith("-")) return false;
  if (startMail[0].startsWith("_")) return false;
  if (startMail[0].endsWith("_")) return false;

  if (endMail[0].startsWith("_")) return false;
  if (endMail[0].endsWith("-")) return false;
  if (endMail[0].startsWith("_")) return false;
  if (endMail[0].endsWith("_")) return false;

  return re.test(email);
};

const validateName = (name) => {
  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)
  const re = /[\d\w(!@#$%^&*())(!"№;%:?*+=)]/giu;


  if (emojiRegEx.test(name)) return t("lk_edit_profile.error.cyrillic");
  if (re.test(name)) return t("lk_edit_profile.error.cyrillic");
  if (name.startsWith("-")) return t("lk_edit_profile.error.cyrillic");
  if (name.endsWith("-")) return t("lk_edit_profile.error.cyrillic");

  // Заглавные буквы

  if (name[0] !== name[0].toUpperCase()) return t("lk_edit_profile.error.format"); 

  for (let char in name) {
    if (char !== "0") {
      if (name[char] !== name[char].toLowerCase()) {
        return t("lk_edit_profile.error.format");
      }
    }
  }

  return false;

};
</script>

<template>
  <div class="header">
    <UserAvatar
      :is-edit="isEdit"
      :image="userFormData.image"
      @update:image="image => userFormData.image = image"
    />
    <div class="header__title">
      {{ userStore.user.firstName }} {{ userStore.user.lastName }}
    </div>
  </div>

  <div class="body">
    <div class="user-control">
      <div
        class="user-control__button user-control__button_cancel"
        @click="emit('update:isEdit', false)"
      >
        <div>
          {{ t('lk_edit_profile.button.cancel') }}
        </div>
      </div>

      <div
        class="user-control__button"
        @click="saveUser"
      >
        <div>
          {{ t('lk_edit_profile.button.save') }}
        </div>
      </div>
    </div>
    <div class="user-block">
      <div class="user-information">
        <div class="user-information__title">
          {{ t('lk_edit_profile.label.email') }}
        </div>
        <div class="user-information__value">
          <TextInput
            :value="userFormData.email"
            place-holder="user@globus.ru"
            :error-message="errorList.email"
            @update:value="newValue => userFormData.email = newValue"
          />
        </div>
      </div>

      <div class="user-information">
        <div class="user-information__title">
          {{ t('lk_edit_profile.label.phone') }}
        </div>
        <div class="user-information__value">
          <TextInput
            :value="userFormData.phone"
            :error-message="errorList.phone"
            place-holder="+7 (800) 555-35-35"
            :mask="`+7 (###) ###-##-##`"
            type="tel"
            @update:value="newValue => userFormData.phone = newValue"
          />

          <!--  TODO: Нет реализации со стороны бэка. Номер телефона должен быть разделен на код страны и цифры      -->
          <!--            <CountryDropdown-->
          <!--              :country-value="phoneCountry"-->
          <!--              :value="userFormData.phone"-->
          <!--              :error="errorList.some((error) => error.type === 'phone')"-->
          <!--              :error-message="errorList.find((error) => error.type === 'phone')?.message"-->
          <!--              type="tel"-->
          <!--              @update:country-value="newValue => phoneCountry = newValue"-->
          <!--              @update:value="newValue => userFormData.phone = newValue"-->
          <!--            />-->
        </div>
      </div>

      <div class="fullname-wrapper">
        <div class="user-information">
          <div class="user-information__title">
            {{ t('lk_edit_profile.label.lastname') }}
          </div>
          <div class="user-information__value">
            <TextInput
              :error-message="errorList.lastName"
              :value="userFormData.lastName"
              :place-holder="t('lk_edit_profile.placeholder.lastname')"
              @update:value="newValue => userFormData.lastName = newValue"
            />
          </div>
        </div>

        <div class="user-information">
          <div class="user-information__title">
            {{ t('lk_edit_profile.label.firstname') }}
          </div>
          <div class="user-information__value">
            <TextInput
              :error-message="errorList.firstName"
              :value="userFormData.firstName"
              :place-holder="t('lk_edit_profile.placeholder.firstname')"
              @update:value="newValue => userFormData.firstName = newValue"
            />
          </div>
        </div>

        <div class="user-information">
          <div class="user-information__title">
            {{ t('lk_edit_profile.label.middlename') }}
          </div>
          <div class="user-information__value">
            <TextInput
              :error-message="errorList.middleName"
              :value="userFormData.middleName"
              :place-holder="t('lk_edit_profile.placeholder.middlename')"
              @update:value="newValue => userFormData.middleName = newValue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  height: 20%;

  &__title {
    font-size: 28px;
    font-weight: 400;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 80%;
  width: 100%;
  gap: 12px;

  .user-control {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 4px;
    .user-control__button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 140px;
      max-width: 200px;
      padding: 0 5px;
      height: 40px;
      background-color: $blue-dark;
      color: #FFFFFF;
      border-radius: 8px;
      gap: 8px;
    }

    .user-control__button_cancel {
      background-color: #FFFFFF;
      color: $grey-dark;
      border: 1px solid #E0E0E0;
    }

  }

  .user-block {
    background-color: $light-blue;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    width: 100%;

    .user-control {

      &__edit {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        padding: 0 5px;
        height: 40px;
        background-color: #FFFFFF;
        color: $grey-dark;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        gap: 8px;
      }
    }

    .user-information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      &__title {
        font-weight: 600;
      }
      &__value {
        color: #898989;
        text-align: start;
      }
    }
  }
}

.fullname-wrapper {
  display: flex;
  gap: 8px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
}
</style>