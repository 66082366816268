<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import UserAvatar from "@/pages/LKPage/LKWindow/LKProfileWindow/LKUserAvatar.vue";
import {useUser} from "@/store/user";
import { t } from "@/controllerLK/GlobusLocalization";

const userStore = useUser(); 

defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:isEdit"]);

</script>

<template>
  <div class="header">
    <UserAvatar
      :is-edit="false"
      :image="userStore.user.image"
    />
    <div class="header__title">
      {{ userStore.user.firstName }} {{ userStore.user.lastName }}
    </div>
  </div>

  <div class="body">
    <div
      class="user-control__button"
      @click="emit('update:isEdit', true)"
    >
      <SvgIcon
        width="14px"
        height="14px"
        name="edit"
      />
      <div class="user-control__button_text">
        {{ t('lk_show_profile.button.edit') }}
      </div>
    </div>
    <div class="user-block">
      <div class="user-information">
        <div class="user-information__title">
          {{ t('lk_show_profile.label.email') }}
        </div>
        <div class="user-information__value">
          {{ userStore.user.email }}
        </div>
      </div>

      <div class="user-information">
        <div class="user-information__title">
          {{ t('lk_show_profile.label.phone') }}
        </div>
        <div class="user-information__value">
          {{ userStore.user.phone }}
        </div>
      </div>

      <div class="user-information">
        <div class="user-information__title">
          {{ t('lk_show_profile.label.fio') }}
        </div>
        <div class="user-information__value">
          {{ userStore.getFullName() }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  height: 20%;

  &__title {
    font-size: 28px;
    font-weight: 400;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 80%;
  width: 100%;
  gap: 12px;

  .user-control__button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    max-width: 200px;
    padding: 0 5px;
    height: 40px;
    background-color: #FFFFFF;
    color: $grey-dark;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    gap: 8px;
    &_text {
      user-select: none;
    }
  }

  .user-block {
    background-color: $light-blue;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;

    .user-control {
      &__edit {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        padding: 0 5px;
        height: 40px;
        background-color: #FFFFFF;
        color: $grey-dark;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        gap: 8px;
      }
    }

    .user-information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      &__title {
        font-weight: 600;
      }
      &__value {
        color: #898989;
        text-align: start;
      }
    }
  }
}
</style>