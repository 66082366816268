<script setup>
import { locales, useGlobusLocal } from "@/controllerLK/GlobusLocalization";
import {useUser} from "@/store/user";
import {ref} from "vue";
import BlockView from "@/components/common/BlockView.vue";

const userStore = useUser();

const isShowDropdown = ref(false);

const changeLang = async (lang) => {
  if (userStore.user.language.toLowerCase() === lang.toLowerCase()) {
    return false;
  }

  if (lang === "ru" || lang === "en") {
    await userStore.changeUserLanguage(lang).then((res) => {
      if (res.data) {
        useGlobusLocal().setLocale(lang);
        isShowDropdown.value = false;
        window.location.reload(); 
      }
    });
  }
};
</script>

<template>
  <div class="language-selector">
    <div
      class="language-selector__selected"
      :class="{'language-selector__selected_active': isShowDropdown}"
      @click="isShowDropdown = !isShowDropdown"
    >
      {{ userStore.user.language }}
    </div>
    <div
      v-if="isShowDropdown"
      class="language-selector__dropdown"
    >
      <div
        v-for="lang in locales"
        :key="lang"
        class="dropdown-item"
        :class="{'dropdown-item_active': userStore.user.language.toLowerCase() === lang.code.toLowerCase()}"
        @click="changeLang(lang.code)"
      >
        {{ lang.code }}
      </div>
    </div>
  </div>
  <BlockView
    v-if="isShowDropdown"
    @click="isShowDropdown = false"
  />
</template>

<style scoped lang="scss">

.language-selector {
  position: relative;

  &__selected {
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    color: #373D3F;
    border-radius: 50%;

    &_active {
      background: #FFF;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 2000;
    top: 50px;
    left: -10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 2px 4px 20px 0 #00203926;
    gap: 4px;

    .dropdown-item {
      font-size: 20px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      user-select: none;
      text-transform: uppercase;
      color: #373D3F;
      border-radius: 8px;

      &_active {
        color: #1F9AFF;
        border: 1px solid #1F9AFF;
      }

      &:hover {
        background: #F4FAFF;
      }
    }
  }
}

</style>