<script setup>
import { t } from "@/controllerLK/GlobusLocalization"; 
import {inject} from "vue";

const errorList = inject("errorList");

const createProjectFormData = inject("createProjectFormData");

</script>

<template>
  <div class="project-name">
    <input
      v-model="createProjectFormData.name"
      :class="{'input-error': errorList.filter((i) => i === 'noName').length > 0}"
      class="project-name__input"
      :placeholder="t('project_modal_name.placeholder.name')"
      type="text"
    >
    <div
      v-if="errorList.filter((i) => i === 'noName').length > 0"
      class="text-error"
    >
      {{ t('project_modal_name.error.required') }}
    </div>
    <div
      v-if="errorList.filter((i) => i === 'noEmoji').length > 0"
      class="text-error"
    >
      {{ t('project_modal_name.error.emoji') }}
    </div>
  </div>

  <div class="project-description">
    <textarea
      v-model="createProjectFormData.description"
      class="project-description__input"
      :placeholder="t('project_modal_name.placeholder.description')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.project-name {
  .project-name__input {
    width: 100%;
    height: 40px;
    padding: 8px 12px 8px 12px;
    border: 1px solid $grey-light;
    border-radius: 8px;
  }
}

.project-description {
  .project-description__input {
    width: 100%;
    height: 190px;
    padding: 8px 12px 8px 12px;
    resize: none;
    border: none;
    border-radius: 8px;
    outline: none;
    background: $light-blue;
  }
}


</style>