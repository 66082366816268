<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {onMounted} from "vue";
import {useStore} from "@/store";

const store = useStore();

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  setTimeout(() => {
    deleteNotification();
  }, props.item.lifeTime);
}); 

const deleteNotification = () => {
  let index =  store.notifications.findIndex(it => it.message === props.item.message);
  store.notifications.splice(index, 1);
};

</script>

<template>
  <div class="notification-block__item">
    <SvgIcon
      name="accept-icon"
      width="44px"
      height="44px"
    />
    {{ item.message }}
  </div>
</template>

<style scoped lang="scss">
.notification-block__item {
  font-size: 22px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 340px;
  height: 85px;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  color: #FFFFFF;
  border-radius: 20px;
  background: #1F9AFF;
  gap: 20px;
}
</style>