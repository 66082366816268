import { mainStorage } from "@/composables/tools/main-storage.tools";
import axios from "axios";

export const LK =  {
  id: "LK",
  state: () => ({
    loadApp: false,
    showContent: false,
    project: "",
    user: "",
    routing: {},

  }),
  actions: {
    goLogin() {
      const event = new CustomEvent("GoToLogin");
      window.dispatchEvent(event);
    },
    setRefresh(data) {
      mainStorage.setItem("refresh_token", data.refresh_token);
    },
    setAccess(data) {
      mainStorage.setItem("access_token", data.access_token);
      mainStorage.setItem("timeEnd", data.expires_in);
    },
    async getToken() {
      try {
        let tokenTimeOld = new Date(mainStorage.getItem("timeEnd"));
        if (+new Date(tokenTimeOld) > Date.now()) {
          return mainStorage.getItem("access_token");
        } else {
          try {
            return await this.getRefresh();
          } catch (e) {
            console.log(e);
            return false;
          }
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getRefresh() {
      let result = await axios.post("/globus/api/Auth/refresh", {
        refresh_token: mainStorage.getItem("refresh_token"),
      });
      this.setRefresh(result.data);
      this.setAccess(result.data);
      return result.data.access_token;
    },
  },
};
