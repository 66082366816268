<script setup>
import HeaderBurgerIcon from "@/assets/icons/HeaderBurgerIcon.vue";
import {useUser} from "@/store/user";
import SvgButton from "@/components/common/svgButton.vue";
import {ref} from "vue";
import BlockView from "@/components/common/BlockView.vue";
import {useStore} from "@/store";
import {getModuleUrl} from "@/composables/tools/services.tools";
import { t } from "@/controllerLK/GlobusLocalization"; 

const userStore = useUser();
const store = useStore();

const isShowDropdown = ref(false);

const goToModule = (module) => {
  window.open(getModuleUrl(module));
};

</script>

<template>
  <div class="services">
    <HeaderBurgerIcon
      class="icon"
      :class="{'icon_active': isShowDropdown}"
      @click="isShowDropdown = !isShowDropdown"
    />

    <div
      v-if="isShowDropdown"
      class="dropdown"
    >
      <div class="dropdown__title">
        {{ t('header_services.title') }}
      </div>
      <div class="dropdown-list">
        <div
          v-for="module in userStore.user.modules
            .filter((storeModule) => storeModule.moduleId !== 'module_main' && storeModule.moduleId !== 'module_map')"
          :key="module.url"
          class="dropdown-list__item"
          :class="{'dropdown-list__item_active': module.moduleId === store.activeModule.dataName}"
          @click="module.moduleId === store.activeModule.dataName ? false : goToModule(module)"
          @mouseenter="module.isShowTooltip = true"
          @mouseleave="module.isShowTooltip = false"
        >
          <SvgButton
            class="service-item__icon"
            width="30px"
            height="30px"
            :name="module.moduleId"
          />
          <div
            v-if="module.isShowTooltip"
            class="dropdown-list__tooltip"
          >
            {{ t('header_services.' + module.moduleId) }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <BlockView
    v-if="isShowDropdown"
    @click="isShowDropdown = false"
  />
</template>

<style scoped lang="scss">
.services {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1F9AFF;

  .dropdown {
    z-index: 2000;
    position: absolute;
    top: 50px;
    right: 0;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 2px 4px 20px 0 #00203926;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__title {
      font-size: 14px;
      font-weight: 500;
      color: #A7A7A7;
    }

    &-list {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 14px;

      &__tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 6px 14px;
        z-index: 2;
        color: #000;
        top: 55px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 1px 2px 10px 0 #131E2726;
      }

      &__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
        user-select: none;
        border-radius: 8px;

        &_active {
          border: 1px solid #1F9AFF;
        }

        &:hover {
          background: #F4FAFF;
        }
      }
    }
  }

  .icon {
    cursor: pointer;
    user-select: none;
    width: 45px;
    height: 45px;

    &_active {
      background: #FFF;
      border-radius: 50%;
    }
  }
}
</style>