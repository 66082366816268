<script setup>
import SvgButton from "@/components/common/svgButton.vue";
import {useStore} from "@/store";
import {goMainPage} from "@/composables/ModuleCommunication";
import { t } from "@/controllerLK/GlobusLocalization"; 

const store = useStore();
</script>

<template>
  <div
    class="project-header"
    @click="goMainPage()"
  >
    <div
      id="header-module-name"
      class="project-header__title"
    >
      {{ t('project_header_logo.' + store.activeModule.dataName) }}
    </div>
    <SvgButton
      :key="store.activeModule.dataName"
      class="project-header__logo"
      height="24px"
      width="24px"
      :name="store.activeModule.dataName"
    />
  </div>
</template>

<style scoped lang="scss">
.project-header {
  display: flex;
  cursor: pointer;
  gap: 6px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
}
</style>