<template>
  <svg
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1_2504"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="75"
      height="75"
    >
      <circle
        cx="37.5"
        cy="37.5"
        r="37.5"
        fill="#E0F0FF"
      />
    </mask>
    <g mask="url(#mask0_1_2504)">
      <path
        d="M63.0263 75.6908V69.3092C63.0263 65.9242 61.6816 62.6779
        59.288 60.2843C56.8945 57.8907 53.6481 56.5461
         50.2631 56.5461H24.7368C21.3518 56.5461 18.1054 57.8907
         15.7119 60.2843C13.3183 62.6779 11.9736 65.9242 11.9736 69.3092V75.6908"
        fill="#1F9AFF"
      />
      <path
        d="M37.5 43.7829C44.5489 43.7829 50.2631 38.0686 50.2631 31.0197C50.2631 23.9708 44.5489 18.2566 37.5
         18.2566C30.4511 18.2566 24.7368 23.9708 24.7368 31.0197C24.7368 38.0686 30.4511 43.7829 37.5 43.7829Z"
        fill="#1F9AFF"
      />
    </g>
  </svg>
</template>