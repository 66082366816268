<script setup>
import RouterItem from "@/components/GlobusHeader/HeaderProject/ProjectHeaderRouting/RouterItem.vue";
import RouterItemProxy from "@/components/GlobusHeader/HeaderProject/ProjectHeaderRouting/RouterItemProxy.vue";
import { useStore } from "@/store";

const store = useStore(); 
</script>

<template>
  <div
    v-if="store.activeModule.dataName === 'module_proxy'"
    class="routing"
  >
    <RouterItemProxy
      v-for="item in store.routing"
      :key="item.id"
      :item="item"
    />
  </div>
  <div
    v-else
    class="routing"
  >
    <RouterItem
      v-for="route in store.routing"
      :key="route.id"
      :item="route"
    />
  </div>
</template>

<style scoped lang="scss">
.routing {
  display: flex;
  gap: 4px;
}
</style>